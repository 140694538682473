<template>
  <div class="home" id="home">
    <div class="home-c">
      <div class="home-butt" @click="isDoubleScreen = !isDoubleScreen">
        <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 16H43C43.5523 16 44 16.4477 44 17V39C44 39.5523 43.5523 40 43 40H30L24.0083 34.0013L18 40H5C4.44772 40 4 39.5523 4 39V17C4 16.4477 4.44772 16 5 16Z"
            fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round" />
          <path
            d="M14 32C16.2091 32 18 30.2091 18 28C18 25.7909 16.2091 24 14 24C11.7909 24 10 25.7909 10 28C10 30.2091 11.7909 32 14 32Z"
            fill="none" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M34 32C36.2091 32 38 30.2091 38 28C38 25.7909 36.2091 24 34 24C31.7909 24 30 25.7909 30 28C30 30.2091 31.7909 32 34 32Z"
            fill="none" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10H42H6Z" fill="none" />
          <path d="M6 10H42" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { StereoEffect } from 'three/examples/jsm/effects/StereoEffect.js';

export default {
  name: 'HomeView',
  data() {
    return {
      camera: null,
      controls: null,
      renderer: null,
      scene: null,
      effect: null,
      isDoubleScreen: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let container = document.querySelector(".home");
      const { clientWidth, clientHeight } = container;
      // 场景
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xaaaaaa);
      // 相机
      const camera = new THREE.PerspectiveCamera(
        90,
        clientWidth / clientHeight,
        1,
        3000
      );
      camera.position.set(200, 200, 200);
      camera.lookAt(0, 0, 0);

      // 渲染器
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      renderer.setSize(clientWidth, clientHeight);
      container.appendChild(renderer.domElement);
      // 
      this.addBox(scene);

      // 控制器
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableZoom = false
      controls.addEventListener("change", function () { });

      //
      const effect = new StereoEffect(renderer);
      effect.setSize(window.innerWidth, window.innerHeight);

      this.scene = scene;
      this.camera = camera;
      this.renderer = renderer;
      this.controls = controls;
      this.effect = effect;
      this.animate();
      window.addEventListener('resize', this.onWindowResize);
    },
    addBox(scene) {
      const geometry = new THREE.SphereGeometry(2000, 500, 500);
      geometry.scale(-1, 1, 1);
      const texture = new THREE.TextureLoader().load(
        new URL(`../assets/0367f64a070aaf9925d5c8c4b7aaed7.jpg`, import.meta.url).href
      );
      const material = new THREE.MeshBasicMaterial({ map: texture });
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();

      //
      if (this.isDoubleScreen) {
        this.effect.render(this.scene, this.camera);
      } else {
        this.renderer.setViewport(0, 0, window.innerWidth, window.innerHeight);
        this.renderer.render(this.scene, this.camera);
      }
    },
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
  }
}
</script>


<style lang="scss">
.home {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.home-c {
  position: fixed;
  top: 10px;
  left: 20px;

  .home-butt {
    float: left;
    margin: 5px;
    padding: 1px 5px;
    border: 1px solid rgba(0,0,0,0);

    &:hover {
      cursor: pointer;
      border: 1px solid #03acfa;
    }
  }
}
</style>